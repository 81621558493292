@tailwind base;
@tailwind components;
@tailwind utilities;

$primaryColor: #0f79be;
$successColor: #61be0f;
.border-primary {
   border-color: $primaryColor;
}
%btn {
   padding: 4px 10px;
   border: black;
   border-radius: 5px;
   color: white;
   min-width: 50px;
}
.input {
   @apply block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none;
}
.btn-primary {
   @extend %btn;
   background: $primaryColor;
}
header {
   z-index: 1;
}
.btn-success {
   @extend %btn;
   background: $successColor;
}
.text-primary {
   color: $primaryColor;
}
.bg-primary {
   background: $primaryColor;
}
a {
   color: $primaryColor;
}
nav a {
   color: white;
}
//
//
//* {
//    margin: 0;
//    padding: 0;
//    box-sizing: border-box;
//}
//
//html {
//    background-color: white;
//    color: #000;
//}
//
//body {
//    min-height: 100vh;
//}
//
//input,
//textarea,
//button,
//select {
//    margin-bottom: 1em;
//    border: 1px solid black;
//}
//
//
//main {
//    max-width: 500px;
//    margin: auto;
//}
//
//section {
//    margin-top: 1em;
//}
//
//article {
//    margin: 0.5em;
//    border: 1px solid #000;
//    border-radius: 10px;
//    padding: 1em;
//}
//
//h1 {
//    font-size: 3.5rem;
//}
//
//h2 {
//    margin-bottom: 1rem;
//}
//
//p {
//    font-family: Arial, Helvetica, sans-serif;
//    line-height: 1.4;
//    font-size: 1.2rem;
//    margin: 0.5em 0;
//}
//
//form {
//    display: flex;
//    flex-direction: column;
//}
//
//textarea {
//    height: 200px;
//}
//
//.postCredit {
//    font-size: 1rem;
//}
//
//.postCredit a,
//.postCredit a:visited {
//    margin-right: 0.5rem;
//    color: black;
//}
//
//.postCredit a:hover,
//.postCredit a:focus {
//    color: hsla(0, 0%, 0%, 0.75);
//}
//
//.excerpt {
//    font-style: italic;
//}
//
//.reactionButton {
//    margin: 0 0.25em 0 0;
//    background: transparent;
//    border: none;
//    color: #000;
//    font-size: 1rem;
//}
//
//.deleteButton {
//    background-color: palevioletred;
//    color: white;
//}
