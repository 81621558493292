$primaryColor: #0f79be;

h2 {
   font-size: 1.4em;
}
h3 {
   font-size: 1.2em;
   color: $primaryColor;
   margin-top: 0.3rem;
   margin-bottom: 0.3rem;
   text-transform: uppercase;
}
h4 {
   font-size: 1em;
   color: $primaryColor;
   margin-top: 0.5rem;
   margin-bottom: 0.3rem;
   text-transform: uppercase;
}
.svgMarginRight {
   svg {
      margin-right: 0.5rem;
   }
}
