.modal {
   position: fixed;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
   background: rgba(0, 0, 0, 0.5);
   display: flex;
   align-items: center;
   justify-content: center;
   padding-top: 5rem;
   z-index: 1;
}
.modal-body {
   padding: 2rem;
   width: 960px;
   border-radius: 5px;
   background: #fff;
   //height: 200px;
   flex: none;
}
